.catalog-product-view {
    .price-tier_price {
        white-space: inherit;
    }

    .prices-tier {
        margin-bottom: rem-calc(20);

        .item {
            background: get-color(alert);
            border-radius: (2px);
            color: $white;
            font-size: rem-calc(16);
            line-height: rem-calc(28);
            margin-bottom: rem-calc(10);
            padding: rem-calc(8 10);
            text-decoration: none;
        }
    }

    .product-info-main {
        .prices-tier {
            margin-top: 0;
        }
    }
    #price-info-modal {
        display: none;
    }
}

/**
    Price info modal stuff
 */
.open-price-info-modal-icon {
    display: none;
    float: left;
}
.price-info-modal {
    .modal-inner-wrap {
        max-width: 700px;
        margin-top: calc(50vh - 100px);

        .modal-content {
            padding-bottom: 45px;
        }
    }
}
.products-grid {
    .open-price-info-modal-icon {
        display: none;
    }
}
/**
    End of Price info modal stuff
 */
.product-info-wrapper .product-info-main .product-info-form-wrapper .product-info-form-row .price-box.price-final_price.non-member-price-box {
    align-items: center;

    .special-price {
        color: black;
        font-size: 20px;
        font-weight: 600;
        padding-right: 12px;
    }

    .not-member-price {
        order: -1;
        align-self: center;
        margin-right: 8px;
    }
}

.product-tier-price .tier-price {
    color: #F7465B;
    font-weight: 600;
    font-size: 24px;
    line-height: 26px;
    margin-bottom: 6px;

    .price-label {
        text-decoration: underline;
        cursor: pointer;
    }
}

.member-modal-content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.product-bulletin {
    list-style: disc;
    margin-left: 1rem;
    color: #1D1D1D;
    font-weight: 700;
    font-size: 18px;
    line-height: 1;
    margin-top: 1rem;

    > li {
        padding: 0 0 0.4em 0;
    }
}

/**
    Inventory info modal stuff
 */

.aw-cus__customstockstatus {
    display: inline-block !important;
}

.inventory-modal-wrapper {
    display: inline-block;
}

.inventory-modal-button {
    margin-left: 1rem;
    font-size: 14px;
    font-weight: 400;

    a {
        font-size: 14px;
        font-weight: 400;
    }
}

.inventory-modal {
    .modal-inner-wrap {
        width: 35rem;
    }

    .modal-header {
        text-align: center;

        .action-close {
            position: absolute !important;
            font-size: xx-large;
        }

        .modal-title {
            border-bottom: 0 !important;
        }
    }

    .modal-footer {
        text-align: center !important;
        border-top: 0 !important;
        padding-top: 0 !important;
    }

    .modal-content {
        padding-top: 1rem;

        .inventory-modal-content {

            .source-element {
                border: 1px solid #eee;
                margin-bottom: 1rem;
                padding: 1rem;
            }

            .source-name {
                display: flex;
                justify-content: space-between;
                font-size: large;

                a {
                    color: #444
                }
            }

            .source-address {
                font-size: medium;
            }

            .source-status {
                position: absolute;
                right: 1rem;
                top: 50%;
                transform: translateY(-50%);
                padding: 0.25rem 0.5rem;
                border-radius: 0.25rem;
                font-size: small;
                display: flex;
                align-items: center;

                &.in-stock {
                    color: #07aaa1;
                }

                &.out-of-stock {
                    color: #F7465B;
                }

                //circle
                &::before {
                    content: '';
                    display: inline-block;
                    width: 10px;
                    height: 10px;
                    border-radius: 50%;
                    margin-right: 0.5rem;
                }
            }

            //circle color
            .source-status.in-stock::before {
                background-color: #07aaa1;
            }

            .source-status.out-of-stock::before {
                background-color: #F7465B;
            }

        }
    }
}

/** Inventory modal mobile design */
@media screen and (max-width: 767px) {

    .inventory-modal {
        padding-right: 1rem;
        padding-left: 0.5rem;
        padding-top: 5rem;
    }

    .inventory-modal .modal-header {
        padding-top: 2rem !important;
    }

    .inventory-modal .modal-header .action-close{
        padding: 5px 0 0 0 !important;
    }

    .inventory-modal .modal-content .inventory-modal-content {
        width: 100% !important;
    }

    .inventory-modal .modal-content .inventory-modal-content .source-element {
        border: 1px solid #d9d9d97f;
        display: grid; /* Using CSS Grid */
        grid-template-areas:
            "name"
            "address"
            "status"; /* Elements order */
    }

    .inventory-modal .modal-content .inventory-modal-content .source-name {
        grid-area: name;
        display: contents;

        a {
            font-size: large;
        }
    }

    .inventory-modal .modal-content .inventory-modal-content .source-address {
        grid-area: address;
        font-size: medium;
    }

    .inventory-modal .modal-content .inventory-modal-content .source-status {
        grid-area: status;
        top: auto;
        transform: none;
        position: static;
        padding: 0;
        font-size: small;

        //circle
        display: flex;
        align-items: center;

        &::before {
            content: '';
            display: inline-block;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            margin-right: 0.5rem;
        }
    }

    .inventory-modal .modal-content .inventory-modal-content .source-status.in-stock::before {
        background-color: #07aaa1;
    }

    .inventory-modal .modal-content .inventory-modal-content .source-status.out-of-stock::before {
        background-color: #F7465B;
    }

    .inventory-modal .modal-footer {
        margin-top: inherit !important;
    }
}

/**
    End of Inventory info modal stuff
 */

.catalog-product-view .page-title-wrapper .lipscore-keyboard-navigable {
    pointer-events: none;
}

.page-title-wrapper #lipscore-rating .lipscore-rating-slider {
    pointer-events: auto;
}

.is-simple-product .stock.available {
    margin-right: auto;
}
